/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-else-return */
/* eslint-disable camelcase,no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */

import Loading from 'components/loading'
import Table from 'components/table'
import { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { errorToast } from 'utils/toasterUtil'
import { useTranslation } from 'react-i18next'
import { Options } from 'components/select'
import { durationOfContractOptions } from 'modules/siteSurvey/components/siteSurveyDropDown'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { sortBy } from 'lodash'
import Button from 'components/button'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { getProjectOverview, getQuoteDetail } from '../queries/quotes'
import EditableInputCell from '../components/EditableInputCell'
import EditableSelectCell from '../components/EditableSelectCell'
import { PricingQueries } from '../queries/pricing'

interface IProjectOverview {
    investmentGroupOptions: Options[]
}

const getTitle = ({
    heading,
    subHeading,
    value,
    onChange,
}: {
    heading: string
    subHeading?: string
    value: boolean
    onChange: any
}) => {
    return (
        <div className="flex flex-col flex-end gap-1 mb-4">
            <p className="text-black text-bold">{heading} </p>
            <div className="flex justify-center items-center">
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    className="flex justify-center items-center text-center"
                />
            </div>
        </div>
    )
}

const initialExportData = [
    'novo_configuration_id',
    'model',
    'description',
    'quantity',
    'duration_of_contract',
    'running_hours',
    'net_all_cost',
    'financial_part',
    'insurance_cost',
    'basic_truck',
    'service_extra_preplist',
    'total_extra',
    'service_extra_special',
    'service_tyre',
    'service_battery',
    'service_extra_attachments',
    'discount_service_all_in',
    'discount_service_pm',
    'total_service',
    'price_per_month_per_truck',
    'total_lease_price',
    'profit_percentage',
    'net_all_cost',
    'novo_quote_price',
    'purchase_total',
    'sales_total',
    'profit_amount',
    'special_price_request_percentage',
    'min_investment_group',
    'discount_service',
    'standard_residual_value',
    'additional_residual_percentage',
]

const ProjectOverview: React.FC<IProjectOverview> = props => {
    const { userExtra, user } = useAuth()
    const { t } = useTranslation()
    const { investmentGroupOptions } = props

    const { quoteId } = useParams()
    const [quotationName, setQuotationName] = useState<string>('')
    const {
        data: projectOverviewData,
        isLoading,
        refetch,
    } = useQuery('projectOverview', () => getProjectOverview(quoteId || ''), {
        enabled: !!quoteId,
        onError: (error: any) => {
            toast(error?.message || t('api.failed_to_fetch_project_overview'), errorToast)
        },
    })
    const [maintenanceType, setMaintenanceType] = useState<'ALL_IN' | 'PREVENTIVE MAINTENANCE'>(
        'ALL_IN',
    )
    const [projectOverview, setProjectOverview] = useState<any>([])
    const [projectOverviewWithoutPricing, setProjectOverviewWithoutPricing] = useState<any>([])
    const [totalProjectOverview, setTotalProjectOverview] = useState<any>({})
    const [exportData, setExportData] = useState<string[]>(initialExportData)

    useQuery(
        ['quoteDetail', quoteId],
        () =>
            getQuoteDetail(
                quoteId || '',
                user.id,
                userExtra.role_id === ROLE_TYPE.technical_support,
            ),
        {
            refetchOnMount: true,
            onSuccess: data => {
                setQuotationName(data?.data.data.quote.quotation_name || '')
            },
        },
    )

    const exportProjectOverview = useMutation(
        ['exportProjectOverview'],
        () => PricingQueries.exportProjectOverview(exportData, parseInt(quoteId || '', 10)),
        {
            onSuccess: data => {
                const url = window.URL.createObjectURL(new Blob([data?.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `project-overview-${quotationName}.xlsx`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.export_data_failed'))
            },
        },
    )

    useEffect(() => {
        const dataWithPricing: any = []
        projectOverviewData?.data?.data?.data_with_pricing?.forEach((project: any) => {
            dataWithPricing.push({ ...project, checked: false })
        })
        setProjectOverview(dataWithPricing)
        if (projectOverviewData?.data?.data?.data_with_pricing.maintenance === 'ALL_IN') {
            setMaintenanceType('ALL_IN')
        } else {
            setMaintenanceType('PREVENTIVE MAINTENANCE')
        }
        setProjectOverviewWithoutPricing(
            projectOverviewData?.data?.data?.data_without_pricing.map((d: any) => {
                return {
                    ...d,
                    hideChecked: true,
                    running_hours: d?.site_survey
                        ? d?.site_survey?.running_hours
                        : d?.additional_running_hours || 0,
                }
            }),
        )
    }, [projectOverviewData?.data?.data])

    const conditionalRowStyles = [
        {
            when: (row: any) => row.total,
            style: {
                backgroundColor: '#d3d3d3',
            },
        },
    ]

    useEffect(() => {
        const selected = projectOverview.filter((project: any) => project.checked)
        const totalCostPrice = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.net_all_cost) * parseFloat(object.quantity)
        }, 0)
        const totalInsurance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.insurance_cost)
        }, 0)
        const totalBasicTruck = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.basic_truck)
        }, 0)
        const totalPreplist = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.service_extra_preplist)
        }, 0)
        const totalSpecial = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.service_extra_special)
        }, 0)
        const totalExtraMaintenance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.total_extra)
        }, 0)
        const totalTireMaintenance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.service_tyre)
        }, 0)
        const totalBatteryMaintenance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.service_battery)
        }, 0)
        const totalAttachmentMaintenance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.service_extra_attachments)
        }, 0)
        const totalPm = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.discount_service_pm)
        }, 0)
        const totalAllIn = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.discount_service_all_in)
        }, 0)
        const totalMaintenance = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.total_service)
        }, 0)
        const totalLeasePrice = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.total_lease_price)
        }, 0)
        const totalPurchase = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.purchase_total)
        }, 0)
        const totalSales = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.sales_total)
        }, 0)
        const totalProfit = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.profit_amount)
        }, 0)
        const totalFinancialPart = selected.reduce((accumulator: any, object: any) => {
            return accumulator + parseFloat(object.financial_part) * parseFloat(object.quantity)
        }, 0)

        const totalObject = {
            total: true,
            financial_part: totalFinancialPart,
            net_all_cost: totalCostPrice,
            insurance_cost: totalInsurance,
            basic_truck: totalBasicTruck,
            service_preplist: totalPreplist,
            service_extra_special: totalSpecial,
            total_extra: totalExtraMaintenance,
            service_tyre: totalTireMaintenance,
            service_battery: totalBatteryMaintenance,
            service_extra_attachments: totalAttachmentMaintenance,
            discount_service_pm: totalPm,
            discount_service_all_in: totalAllIn,
            total_service: totalMaintenance,
            total_lease_price: totalLeasePrice,
            purchase_total: totalPurchase,
            sales_total: totalSales,
            profit_amount: totalProfit,
        }
        setTotalProjectOverview(totalObject)
    }, [projectOverview])

    const idColumns = [
        {
            name: t('api.select'),
            left: true,
            width: '5rem',
            cell: (
                row: {
                    novo_configuration_id: number
                    checked: boolean
                    hideChecked: boolean
                    total: boolean
                },
                index: number,
            ) => {
                return (
                    <div>
                        {/* {row.isParent && ( */}
                        {row.hideChecked}
                        {!row.total ? (
                            !row.hideChecked ? (
                                <Checkbox
                                    checked={row.checked}
                                    onClick={() => {
                                        const tempProject = projectOverview.map(
                                            (project: any, i: number) => {
                                                if (
                                                    project.novo_configuration_id ===
                                                        row.novo_configuration_id &&
                                                    index === i
                                                ) {
                                                    return {
                                                        ...project,
                                                        checked: !project.checked,
                                                    }
                                                } else {
                                                    return project
                                                }
                                            },
                                        )
                                        setProjectOverview(tempProject)
                                    }}
                                />
                            ) : (
                                <p>{t('api.no_pricing_data')}</p>
                            )
                        ) : (
                            <p>{t('api.total')}</p>
                        )}
                        {/* )} */}
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.configuration_id'),
                value: exportData.includes('novo_configuration_id'),
                onChange: () => {
                    if (exportData.includes('novo_configuration_id')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'novo_configuration_id',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'novo_configuration_id'])
                    }
                },
            }),
            left: true,
            width: '140px',
            cell: (row: { novo_configuration_id: number }) => {
                return <p className="text-black w-full text-bold">{row.novo_configuration_id}</p>
            },
        },
        {
            name: getTitle({
                heading: t('api.model'),
                value: exportData.includes('model'),
                onChange: () => {
                    if (exportData.includes('model')) {
                        const tempExportData = exportData.filter((data: string) => data !== 'model')
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'model'])
                    }
                },
            }),
            left: true,
            width: '8rem',
            cell: (row: { model: string }) => {
                return (
                    <div>
                        <p title={row.model} className="text-black text-bold">
                            {row.model}
                        </p>
                    </div>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.description'),
                value: exportData.includes('description'),
                onChange: () => {
                    if (exportData.includes('description')) {
                        const tempExportData = exportData.filter((data: string) => data !== 'model')
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'description'])
                    }
                },
            }),
            left: true,
            width: '16rem',
            cell: (row: { description: string }) => {
                return (
                    <div>
                        <p className="text-black text-bold truncate max-w-[14rem]">
                            {row.description || '-'}
                        </p>
                    </div>
                )
            },
        },
    ]

    const columns = [
        // {
        //     name: getTitle({
        //         heading: t('api.return'),
        //         value: exportData.includes('return'),
        //         onChange: () => {
        //             if (exportData.includes('return')) {
        //                 const tempExportData = exportData.filter(
        //                     (data: string) => data !== 'return',
        //                 )
        //                 setExportData(tempExportData)
        //             } else {
        //                 setExportData([...exportData, 'return'])
        //             }
        //         },
        //     }),
        //     left: true,
        //     width: '8rm',
        //     center: true,
        //     cell: (row: { return: string; total: number }) => {
        //         return (
        //             <p className="text-black w-full text-center text-bold">
        //                 {row.total ? null : row.return ? t('api.yes') : t('api.no')}
        //             </p>
        //         )
        //     },
        // },
        // {
        //     name: getTitle({
        //         heading: t('api.return_description'),
        //         value: exportData.includes('return_description'),
        //         onChange: () => {
        //             if (exportData.includes('return_description')) {
        //                 const tempExportData = exportData.filter(
        //                     (data: string) => data !== 'return_description',
        //                 )
        //                 setExportData(tempExportData)
        //             } else {
        //                 setExportData([...exportData, 'return_description'])
        //             }
        //         },
        //     }),
        //     left: true,
        //     width: '8rem',
        //     cell: (row: { return_description: string; total: number }) => {
        //         return <p className="text-black text-bold">{row.total && row.return_description}</p>
        //     },
        // },
        {
            name: getTitle({
                heading: t('api.quantity'),
                value: exportData.includes('quantity'),
                onChange: () => {
                    if (exportData.includes('quantity')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'quantity',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'quantity'])
                    }
                },
            }),
            left: true,
            center: true,
            width: '4rm',
            cell: (row: { quantity: string }) => {
                return <p className="text-black text-bold">{row.quantity}</p>
            },
        },
        {
            name: getTitle({
                heading: t('api.duration_of_contract'),
                value: exportData.includes('duration_of_contract'),
                onChange: () => {
                    if (exportData.includes('duration_of_contract')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'duration_of_contract',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'duration_of_contract'])
                    }
                },
            }),
            center: true,
            width: '12rem',
            cell: (row: {
                novo_configuration_id: string
                duration_of_contract: number
                configuration_price_id: number
                total: boolean
            }) => {
                if (row.total) return null
                return (
                    <EditableSelectCell
                        name="duration_of_contract"
                        disabled
                        options={durationOfContractOptions}
                        originalValue={row.duration_of_contract}
                        unit={t('api.months')}
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.running_hours'),
                value: exportData.includes('running_hours'),
                onChange: (row: { running_hours: number; additional_running_hours: number }) => {
                    if (exportData.includes('running_hours')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'running_hours',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'running_hours'])
                    }
                },
            }),
            center: true,
            width: '10rem',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                running_hours: number
                additional_running_hours: number
                total: boolean
            }) => {
                if (row.total) {
                    return null
                }
                return (
                    <EditableInputCell
                        name="runningHours"
                        disabled
                        originalValue={row.running_hours || row.additional_running_hours || 0}
                        type="time"
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.sales_price_per_truck'),
                value: exportData.includes('novo_quote_price'),
                onChange: () => {
                    if (exportData.includes('novo_quote_price')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'novo_quote_price',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'novo_quote_price'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: {
                novo_quote_price: string
                total: boolean
                hideChecked: boolean
                manual_price: string
                is_manual_price_valid: boolean
            }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {!row.total
                            ? row.manual_price && row.is_manual_price_valid
                                ? formatCurrency(Number(parseFloat(row.manual_price).toFixed(0)))
                                : formatCurrency(
                                      Number(parseFloat(row.novo_quote_price).toFixed(0)),
                                  )
                            : null}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.lease_financial_or_operational'),
                value: exportData.includes('financial_part'),
                onChange: () => {
                    if (exportData.includes('financial_part')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'financial_part',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'financial_part'])
                    }
                },
            }),
            right: true,
            width: '9rem',
            cell: (row: { financial_part: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return <p className="text-black text-bold">{formatCurrency(row.financial_part)}</p>
            },
        },
        {
            name: getTitle({
                heading: t('api.insurance'),
                value: exportData.includes('insurance_cost'),
                onChange: () => {
                    if (exportData.includes('insurance_cost')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'insurance_cost',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'insurance_cost'])
                    }
                },
            }),
            right: true,
            width: '9rem',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                insurance_cost: string
                hideChecked: boolean
            }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <EditableInputCell
                        name="insurance_cost"
                        disabled
                        originalValue={parseFloat(row.insurance_cost)}
                        type="currency"
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.investment_group'),
                value: exportData.includes('min_investment_group'),
                onChange: () => {
                    if (exportData.includes('min_investment_group')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'min_investment_group',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'min_investment_group'])
                    }
                },
            }),
            right: true,
            width: '180px',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                min_investment_group: number
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <EditableSelectCell
                        name="investmentGroup"
                        options={investmentGroupOptions}
                        disabled
                        originalValue={row.min_investment_group}
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_truck'),
                value: exportData.includes('basic_truck'),
                onChange: () => {
                    if (exportData.includes('basic_truck')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'basic_truck',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'basic_truck'])
                    }
                },
            }),
            right: true,
            width: '180px',
            cell: (row: { basic_truck: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.basic_truck)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_preplist'),
                value: exportData.includes('service_extra_preplist'),
                onChange: () => {
                    if (exportData.includes('service_extra_preplist')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'service_extra_preplist',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'service_extra_preplist'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { total_preplist: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.total_preplist || 0)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_special'),
                value: exportData.includes('service_extra_special'),
                onChange: () => {
                    if (exportData.includes('service_extra_special')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'service_extra_special',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'service_extra_special'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { service_extra_special: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.service_extra_special || 0)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_maintenance'),
                value: exportData.includes('total_extra'),
                onChange: () => {
                    if (exportData.includes('total_extra')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'total_extra',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'total_extra'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { total_extra: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.total_extra || 0)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_tires'),
                value: exportData.includes('service_tyre'),
                onChange: () => {
                    if (exportData.includes('service_tyre')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'service_tyre',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'service_tyre'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { service_tyre: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.service_tyre)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_battery'),
                value: exportData.includes('service_battery'),
                onChange: () => {
                    if (exportData.includes('service_battery')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'service_battery',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'service_battery'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { service_battery: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.service_battery)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.service_extra_attachment'),
                value: exportData.includes('service_extra_attachments'),
                onChange: () => {
                    if (exportData.includes('service_extra_attachments')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'service_extra_attachments',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'service_extra_attachments'])
                    }
                },
            }),
            right: true,
            width: '14rem',
            cell: (row: { service_extra_attachments: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(row.service_extra_attachments)}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.discount_service_all_in'),
                value: exportData.includes('discount_service_all_in'),
                onChange: () => {
                    if (exportData.includes('discount_service_all_in')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'discount_service_all_in',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'discount_service_all_in'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: {
                discount_service_all_in: number
                maintenance: string
                additional_maintenance: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }

                if (
                    !(
                        row.maintenance === 'ALL_IN' ||
                        row.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ||
                        row.additional_maintenance === 'ALL_IN' ||
                        row.additional_maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE'
                    )
                ) {
                    return <p className="text-black text-right w-full text-bold">-</p>
                }

                return (
                    <p className="text-black text-right w-full text-bold">
                        {row.discount_service_all_in || '-'} {row.discount_service_all_in && '%'}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.pm_maintenance'),
                value: exportData.includes('discount_service_pm'),
                onChange: () => {
                    if (exportData.includes('discount_service_pm')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'discount_service_pm',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'discount_service_pm'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: {
                discount_service_pm: number
                maintenance: string
                additional_maintenance: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }

                if (
                    !(
                        row.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                        row.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ||
                        row.additional_maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                        row.additional_maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE'
                    )
                ) {
                    return <p className="text-black text-right w-full text-bold">-</p>
                }

                return (
                    <p className="text-black text-right w-full text-bold">
                        {row.discount_service_pm || '-'} {row.discount_service_pm && '%'}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.total_maintenance'),
                value: exportData.includes('total_service'),
                onChange: () => {
                    if (exportData.includes('total_service')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'total_service',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'total_service'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: { total_service: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(String(row.total_service)).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.price_per_month_per_truck'),
                value: exportData.includes('price_per_month_per_truck'),
                onChange: () => {
                    if (exportData.includes('price_per_month_per_truck')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'price_per_month_per_truck',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'price_per_month_per_truck'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            omit: (row: { maintenance: string }) => row.maintenance !== 'ALL_IN',
            cell: (row: { price_per_month_per_truck: number; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(
                            Number(parseFloat(String(row.price_per_month_per_truck)).toFixed(0)),
                        )}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.lease_price_total'),
                value: exportData.includes('total_lease_price'),
                onChange: () => {
                    if (exportData.includes('total_lease_price')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'total_lease_price',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'total_lease_price'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: { total_lease_price: string; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(row.total_lease_price).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.margin'),
                value: exportData.includes('profit_percentage'),
                onChange: () => {
                    if (exportData.includes('profit_percentage')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'profit_percentage',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'profit_percentage'])
                    }
                },
            }),
            right: true,
            width: '7rem',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                profit_percentage: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <EditableInputCell
                        name="profit_percentage"
                        disabled
                        originalValue={parseFloat(row.profit_percentage)}
                        type="percentage"
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.purchase_price_per_truck'),
                value: exportData.includes('net_all_cost'),
                onChange: () => {
                    if (exportData.includes('net_all_cost')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'net_all_cost',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'net_all_cost'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: { net_all_cost: number; total: boolean; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {!row.total
                            ? formatCurrency(
                                  Number(parseFloat(String(row.net_all_cost)).toFixed(0)),
                              )
                            : null}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.sales_price_per_truck'),
                value: exportData.includes('novo_quote_price'),
                onChange: () => {
                    if (exportData.includes('novo_quote_price')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'novo_quote_price',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'novo_quote_price'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: {
                novo_quote_price: string
                total: boolean
                hideChecked: boolean
                manual_price: string
                is_manual_price_valid: boolean
            }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {!row.total
                            ? row.manual_price && row.is_manual_price_valid
                                ? formatCurrency(Number(parseFloat(row.manual_price).toFixed(0)))
                                : formatCurrency(
                                      Number(parseFloat(row.novo_quote_price).toFixed(0)),
                                  )
                            : null}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.purchase_total'),
                value: exportData.includes('purchase_total'),
                onChange: () => {
                    if (exportData.includes('purchase_total')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'purchase_total',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'purchase_total'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: { purchase_total: string; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(String(row.purchase_total)).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.sales_total'),
                value: exportData.includes('sales_total'),
                onChange: () => {
                    if (exportData.includes('sales_total')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'sales_total',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'sales_total'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: { sales_total: string; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(String(row.sales_total)).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.profit_per_truck'),
                value: exportData.includes('profit_amount'),
                onChange: () => {
                    if (exportData.includes('profit_amount')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'profit_amount',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'profit_amount'])
                    }
                },
            }),
            right: true,
            width: '10rem',
            cell: (row: { profit_amount: string; hideChecked: boolean }) => {
                if (row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(String(row.profit_amount)).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.spr'),
                value: exportData.includes('special_price_request_percentage'),
                onChange: (e: any) => {
                    if (
                        e.target.checked &&
                        exportData.includes('special_price_request_percentage')
                    ) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'special_price_request_percentage',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'special_price_request_percentage'])
                    }
                },
            }),
            right: true,
            width: '6rem',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                special_price_request_percentage: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <EditableInputCell
                        name="special_price_request_percentage"
                        originalValue={parseFloat(row.special_price_request_percentage)}
                        type="percentage"
                        disabled
                        onChange={() => {}}
                    />
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.discount_service'),
                value: exportData.includes('discount_service'),
                onChange: () => {
                    if (exportData.includes('discount_service')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'discount_service',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'discount_service'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: {
                discount_service_all_in: string
                discount_service_pm: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {maintenanceType === 'ALL_IN'
                            ? row.discount_service_all_in
                            : row.discount_service_pm}
                        %
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.standard_residual_value'),
                value: exportData.includes('standard_residual_value'),
                onChange: () => {
                    if (exportData.includes('standard_residual_value')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'standard_residual_value',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'standard_residual_value'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: {
                standard_residual_value: number
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <p className="text-black text-right w-full text-bold">
                        {row.standard_residual_value} %
                    </p>
                )
            },
        },
        {
            name: getTitle({
                heading: t('api.additional_residual_value'),
                value: exportData.includes('additional_residual_percentage'),
                onChange: () => {
                    if (exportData.includes('additional_residual_percentage')) {
                        const tempExportData = exportData.filter(
                            (data: string) => data !== 'additional_residual_percentage',
                        )
                        setExportData(tempExportData)
                    } else {
                        setExportData([...exportData, 'additional_residual_percentage'])
                    }
                },
            }),
            right: true,
            width: '12rem',
            cell: (row: {
                novo_configuration_id: string
                configuration_price_id: number
                additional_residual_percentage: string
                total: boolean
                hideChecked: boolean
            }) => {
                if (row.total || row.hideChecked) {
                    return null
                }
                return (
                    <EditableInputCell
                        name="extras"
                        disabled
                        originalValue={parseFloat(row.additional_residual_percentage)}
                        type="percentage"
                        onChange={() => {}}
                    />
                )
            },
        },
    ]

    if (isLoading) {
        return (
            <div className="w-full h-full grid place-content-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 h-full">
            <div className="flex gap-4">
                <div className="w-full flex justify-end gap-4">
                    <div className="w-fit">
                        <Button
                            label={t('api.export')}
                            isLoading={exportProjectOverview.isLoading}
                            disabled={exportProjectOverview.isLoading}
                            onClick={() => exportProjectOverview.mutate()}
                            variant="outline"
                        />
                    </div>
                </div>
            </div>
            {projectOverview?.length > 0 || projectOverviewWithoutPricing?.length > 0 ? (
                <div className="flex">
                    <div className="w-fit">
                        <Table
                            columns={idColumns}
                            conditionalRowStyles={conditionalRowStyles}
                            data={
                                [
                                    ...projectOverview,
                                    ...projectOverviewWithoutPricing,
                                    totalProjectOverview,
                                ] || []
                            }
                        />
                    </div>
                    <Table
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        data={
                            [
                                ...projectOverview,
                                ...projectOverviewWithoutPricing,
                                totalProjectOverview,
                            ] || []
                        }
                    />
                </div>
            ) : (
                <p>{t('message.no_project_with_price_available')}</p>
            )}
        </div>
    )
}

export default ProjectOverview
