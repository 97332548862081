import Button from 'components/button'
import Input from 'components/input'
import Select, { Options } from 'components/select'
import { FormikProps, useFormik } from 'formik'
import {
    financeOptions,
    maintenanceOptions,
    yesNoOptions,
} from 'modules/siteSurvey/components/siteSurveyDropDown'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { IAdditionalConfigurationData, PricingQueries } from '../queries/pricing'

interface ISiteSurveyNotConnectedForm {
    financing: string
    maintenance: string
    insurance: string
    running_hours: number
    show_all_prices_separate: string
    return: boolean
    return_description: string
    category: string
    usedTruckPriceType?: 'oude_price' | 'bronze' | 'silver' | 'gold'
}

const SiteSurveyNotConnected = (params: {
    refetchInitial: () => void
    refetchAdditionalData: () => void
}) => {
    const { t } = useTranslation()
    const { refetchInitial, refetchAdditionalData } = params
    const queryClient = useQueryClient()
    const { configurationId } = useParams()

    const saveAdditionalConfigurationData = useMutation(
        ['saveAdditionalConfigurationData'],
        (p: IAdditionalConfigurationData) => PricingQueries.createAdditionalConfigurationData(p),
        {
            onSuccess: () => {
                toast.success(t('message.save_additional_configuration_data_success'))
                queryClient.invalidateQueries({
                    queryKey: ['additionalData', 'getPricingInitialData'],
                })
                refetchInitial()
                refetchAdditionalData()
            },
            onError: () => {
                toast.error(t('message.save_additional_configuration_data_failed'))
            },
        },
    )

    const usedTruckOptionsData = useQuery(
        ['usedTruckOptionsData', configurationId],
        () => PricingQueries.getUsedTruckPricingOptionsByConfigurationId(Number(configurationId)),
        {
            enabled: Boolean(configurationId),
        },
    )

    const showAllPriceSeparateOptions: Options[] = [
        {
            label: t('api.yes'),
            value: 'yes',
        },
        {
            label: t('api.no'),
            value: 'no',
        },
        {
            label: t('api.only_service_separated'),
            value: 'only service separated',
        },
        {
            label: t('api.only_insurance_separated'),
            value: 'only insurance separated',
        },
    ]

    const categoryOptions: Options[] = [
        {
            label: t('api.category_1'),
            value: '1',
        },
        {
            label: t('api.category_2'),
            value: '2',
        },
        {
            label: t('api.category_3'),
            value: '3',
        },
    ]

    const formik: FormikProps<ISiteSurveyNotConnectedForm> = useFormik<ISiteSurveyNotConnectedForm>(
        {
            initialValues: {
                financing: '',
                maintenance: '',
                insurance: '',
                running_hours: 0,
                show_all_prices_separate: '',
                return: false,
                return_description: '',
                category: '',
                usedTruckPriceType: 'oude_price',
            },
            validationSchema: Yup.object().shape({
                financing: Yup.string().required(t('message.financing_required')),
                maintenance: Yup.string().required(t('message.maintenance_required')),
                insurance: Yup.string().required(t('message.insurance_required')),
                running_hours: Yup.number().min(1, t('message.running_hours_required')),
                show_all_prices_separate: Yup.string().required(
                    t('message.show_all_prices_separate_required'),
                ),
                return: Yup.boolean().required(t('message.return_required')),
                category: Yup.string().required(t('message.category_required')),
            }),
            onSubmit: (values: ISiteSurveyNotConnectedForm) => {
                saveAdditionalConfigurationData.mutate({
                    configuration_id: parseInt(String(configurationId || '0'), 10),
                    financing: values.financing,
                    insurance: values.insurance,
                    maintenance: values.maintenance,
                    return: values.return,
                    running_hours: values.running_hours,
                    return_description: values.return_description,
                    show_all_prices_separate: values.show_all_prices_separate,
                    theoretical_category: values.category,
                    used_truck_price_type: values.usedTruckPriceType,
                })
            },
        },
    )

    return (
        <div className="h-full w-full flex flex-col gap-4">
            <p className="text-lg h-fit font-medium underline underline-offset-8 decoration-4 decoration-primary text-bold text-xl cursor-pointer hover:border-gray-300 w-full">
                {t('api.pricing')}
            </p>
            <p>{t('api.site_survey_not_connected_please_fill_this_form_to_continue')}</p>
            <form className="flex w-full flex-col gap-4" onSubmit={formik.handleSubmit}>
                <Select
                    horizontal
                    label={t('fields.financing')}
                    value={formik.values.financing}
                    showPlaceHolder
                    required
                    placeHolderOption={t('fields.financing_placeholder')}
                    options={financeOptions}
                    onChange={formik.handleChange}
                    error={formik.touched.financing && formik.errors.financing}
                    name="financing"
                />
                <Select
                    horizontal
                    label={t('fields.maintenance')}
                    value={formik.values.maintenance}
                    required
                    options={maintenanceOptions}
                    placeHolderOption={`${t('fields.select')}  ${t('fields.maintenance')}`}
                    showPlaceHolder
                    onChange={formik.handleChange}
                    error={formik.touched.maintenance && formik.errors.maintenance}
                    name="maintenance"
                />
                <Select
                    horizontal
                    label={t('fields.insurance')}
                    required
                    value={formik.values.insurance}
                    options={yesNoOptions}
                    placeHolderOption={`${t('fields.select')}  ${t('fields.insurance')}`}
                    showPlaceHolder
                    onChange={formik.handleChange}
                    error={formik.touched.insurance && formik.errors.insurance}
                    name="insurance"
                />
                <Select
                    horizontal
                    label={t('fields.show_all_prices_separate')}
                    value={formik.values.show_all_prices_separate}
                    required
                    showPlaceHolder
                    placeHolderOption={`${t('fields.select')} ${t(
                        'fields.show_all_prices_separate',
                    )}`}
                    options={showAllPriceSeparateOptions}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.show_all_prices_separate &&
                        formik.errors.show_all_prices_separate
                    }
                    name="show_all_prices_separate"
                />
                <Input
                    horizontal
                    type="number"
                    label={t('fields.running_hours')}
                    required
                    placeholder={t('fields.enter_running_hours')}
                    value={formik.values.running_hours}
                    onChange={formik.handleChange}
                    error={formik.touched.running_hours && formik.errors.running_hours}
                    name="running_hours"
                />
                <Select
                    horizontal
                    label={t('fields.return')}
                    required
                    value={formik.values.return ? 'yes' : 'no'}
                    options={yesNoOptions}
                    placeHolderOption={t('fields.select') + t('fields.return')}
                    showPlaceHolder
                    onChange={e => {
                        formik.setFieldValue('return', e.target.value === 'yes')
                    }}
                    error={formik.touched.return && formik.errors.return}
                    name="return"
                />
                {formik.values.return && (
                    <Input
                        horizontal
                        label={t('fields.manual_enter')}
                        placeholder={t('fields.manual_enter')}
                        value={formik.values.return_description}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.return_description && formik.errors.return_description
                        }
                        name="return_description"
                    />
                )}
                <Select
                    horizontal
                    required
                    label={t('fields.category')}
                    value={formik.values.category}
                    options={categoryOptions}
                    placeHolderOption={`${t('fields.select')} ${t('fields.category')}`}
                    showPlaceHolder
                    onChange={formik.handleChange}
                    error={formik.touched.category && formik.errors.category}
                    name="category"
                />
                {usedTruckOptionsData.data?.data?.data && (
                    <Select
                        horizontal
                        label={t('fields.used_truck_price_type')}
                        value={formik.values.usedTruckPriceType}
                        options={usedTruckOptionsData.data?.data?.data || []}
                        placeHolderOption={`${t('fields.select')} ${t(
                            'fields.used_truck_price_type',
                        )}`}
                        showPlaceHolder
                        onChange={e => formik.setFieldValue('usedTruckPriceType', e.target.value)}
                        error={formik.touched.maintenance && formik.errors.return}
                        name="return"
                    />
                )}
                <div className="flex justify-end gap-4 w-full">
                    <div className="my-2">
                        <Button
                            label={t('api.save')}
                            variant="primary"
                            type="submit"
                            isLoading={saveAdditionalConfigurationData.isLoading}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SiteSurveyNotConnected
