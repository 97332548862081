import { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { filter } from 'lodash'
import Fuse from 'fuse.js'

import Input from 'components/input'
import Table from 'components/table'
import Tabs, { TabType } from 'components/tabs'

import { truncateHour } from 'utils/formatDate'
import { getPillsColor } from 'utils/pills'

import { useTranslation } from 'react-i18next'
import { getAllOrder, getAllTeamOrder } from './queries/order'
import { ROLE_TYPE, useAuth } from '../auth/context/useAuth'

const cardClass =
    'w-full bg-white p-4 rounded-md overscroll-auto overflow-auto flex flex-col h-screen'

const tabs: TabType[] = [
    {
        name: 'fields.my_order',
        value: 'my-order',
    },
    {
        name: 'fields.team_order',
        value: 'team-order',
    },
]

const Order = () => {
    const { t } = useTranslation()
    const [query, setQuery] = useState<string>('')
    const options = [
        {
            label: t('fields.all'),
            value: 'all',
        },
        {
            label: t('fields.submitted'),
            value: 'submitted order',
        },
        {
            label: t('fields.pending'),
            value: 'pending order',
        },
        {
            label: t('fields.cancelled'),
            value: 'cancelled',
        },
    ]
    const [status, setStatus] = useState(options[0])
    const { user, userExtra } = useAuth()

    const [activeTab, setActiveTab] = useState(
        userExtra.role === 'Sales management' ? tabs[1] : tabs[0],
    )

    const columns = [
        {
            name: t('api.sn_no'),
            left: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: any, index: number) => (
                <h1 className="text-black text-semibold text-base">{index + 1 || 'unknown'}</h1>
            ),
            sortable: true,
            width: '130px',
        },
        {
            name: t('api.order_name'),
            left: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { order_name: string; id: string | number; quote_id: string | number }) => {
                return (
                    <Link
                        to={`/order/${row.id}/${row.quote_id}`}
                        className="text-semibold text-base"
                    >
                        {row?.order_name || t('api.unknown')}
                    </Link>
                )
            },
            height: 'fit-content',
            width: '250px',
            sortable: true,
            selector: (row: { order_name: string }) => row.order_name,
        },
        {
            name: t('api.customer_name'),
            left: true,
            height: 'fit-content',
            selector: (row: { quotation_for: string }) => row.quotation_for,

            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { quotation_for: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {row?.quotation_for || t('api.unknown')}
                    </h1>
                )
            },
            sortable: true,
        },
        {
            name: t('api.created_by'),
            left: true,
            height: 'fit-content',
            selector: (row: { user_name: string }) => row.user_name,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { user_name: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">{row?.user_name || '-'}</h1>
                )
            },
            sortable: true,
            omit: activeTab === tabs[0],
        },
        {
            name: t('api.order_status'),
            center: true,
            height: 'fit-content',
            selector: (row: { status: string }) => row.status,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (row: { status: string }) => {
                const color = getPillsColor(
                    row.status === 'pending order' ? 'pending' : 'submitted',
                )
                const baseType =
                    String(row.status) === 'pending order' ? t(`api.pending`) : t(`api.submitted`)
                return (
                    <div
                        title={baseType}
                        className={classNames(
                            'capitalize font-bold',
                            color.bg,
                            color.text,
                            'rounded-full py-2 px-4 w-40 overflow-hidden text-center',
                        )}
                    >
                        {baseType}
                    </div>
                )
            },
            sortable: true,
        },
        {
            name: t('api.order_date'),
            center: true,
            height: 'fit-content',
            cell: (row: { order_date: string }) => {
                return (
                    <h1 className="text-black text-semibold text-base">
                        {truncateHour(String(row.order_date))}
                    </h1>
                )
            },
            sortable: true,
        },
    ]

    const { data, isLoading, refetch } = useQuery(
        ['orderList', activeTab],
        () =>
            activeTab.value === 'team-order'
                ? getAllTeamOrder(user.id)
                : getAllOrder(user.id, userExtra.role_id === ROLE_TYPE.technical_support),
        {
            enabled: !!user.id,
        },
    )

    const fuse = new Fuse(data?.data || [], {
        keys: ['order_name', 'user_name', 'quotation_for'],
        threshold: 0.3,
    })
    const results = fuse.search(query)
    const orderResults = query ? results.map(character => character.item) : data?.data

    const getDataBasedOnStatus = () => {
        if (status.value === 'all') {
            return orderResults
        }
        return filter(
            orderResults || [],
            (item: { status: string }) => item.status === status.value,
        )
    }

    return (
        <div className={cardClass}>
            <Tabs
                initialTab={activeTab}
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                    refetch()
                }}
                leftContent={
                    <div className="flex flex-wrap items-center gap-4 z-50 ">
                        <div className="w-full lg:w-[10vw] z-[99999999] ">
                            <Select
                                options={options}
                                value={status}
                                onChange={(value: any) => setStatus(value)}
                            />
                        </div>
                        <div className="w-full lg:flex-1">
                            <Input
                                type="text"
                                value={query}
                                name="search"
                                leftContent={<BiSearch />}
                                onChange={event => {
                                    setQuery(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <div className="border rounded-md border-gray overflow-auto">
                <Table
                    columns={columns}
                    data={getDataBasedOnStatus() || []}
                    loading={isLoading}
                    striped
                    pagination
                    totalRows={25}
                />
            </div>
        </div>
    )
}

export default Order
