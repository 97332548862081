/* eslint-disable camelcase */
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/currency/formatCurrency'

interface ConfigurationItem {
    id: number
    label: string
    checked: boolean
}

interface IConnectExtraToConfigurationsModal {
    id: number
    netTotal: number
    order_type: number
    model: string
    description: string
    price: number
    quantity: number
    configurationList: ConfigurationItem[]
    setConfigurationList: (list: ConfigurationItem[]) => void
}

const ConnectExtraToConfigurationsModal = ({
    id,
    netTotal,
    order_type,
    price,
    quantity,
    configurationList,
    setConfigurationList,
    model,
    description,
}: IConnectExtraToConfigurationsModal) => {
    const { t } = useTranslation()

    const toggleConfiguration = (configId: number) => {
        setConfigurationList(
            configurationList.map(item =>
                item.id === configId ? { ...item, checked: !item.checked } : item,
            ),
        )
    }

    return (
        <div className="flex flex-col gap-3 text-sm">
            <p className="text-lg font-medium">{t('api.connect_extra_to_configurations')}</p>

            <div className="space-y-1">
                <div className="flex justify-between">
                    <span className="text-gray-600">{t('fields.id')}:</span>
                    <span className="text-slate-500">{id}</span>
                </div>
                {model && (
                    <div className="flex justify-between">
                        <span className="text-gray-600 text-left">{t('fields.model')}:</span>
                        <span className="text-slate-500">{model}</span>
                    </div>
                )}
                {description && (
                    <div className="flex justify-between">
                        <span className="text-gray-600 text-left">{t('fields.description')}:</span>
                        <span className="text-right max-w-64 text-slate-500">{description}</span>
                    </div>
                )}
                <div className="flex justify-between">
                    <span className="text-gray-600">{t('fields.order_type')}:</span>
                    <span className="text-slate-500">
                        {order_type === 2 ? t('api.compulsory') : t('api.optional')}
                    </span>
                </div>
                <div className="flex justify-between">
                    <span className="text-gray-600">{t('fields.price')}:</span>
                    <span className="text-slate-500">{formatCurrency(price)}</span>
                </div>
                <div className="flex justify-between">
                    <span className="text-gray-600">{t('fields.quantity')}:</span>
                    <span className="text-slate-500">{quantity}</span>
                </div>
                <div className="flex justify-between">
                    <span className="text-gray-600">{t('fields.net_total')}:</span>
                    <span className="text-slate-500">{formatCurrency(netTotal)}</span>
                </div>
            </div>
            <hr />
            <div className="mt-2">
                <p className="text-lg font-medium">{t('api.select_configurations')}</p>
                {configurationList.length === 0 ? (
                    <p className="text-gray-500">{t('api.no_configuration_available')}</p>
                ) : (
                    <div className="flex flex-col gap-2">
                        {configurationList.map(item => (
                            <div className="flex justify-between items-center" key={item.id}>
                                <p className="text-left">
                                    {t('api.id')} : {item.id} {item.label}
                                </p>
                                <Checkbox
                                    checked={item.checked}
                                    onChange={() => toggleConfiguration(item.id)}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ConnectExtraToConfigurationsModal
