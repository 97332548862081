import Input from 'components/input'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { MdCancel, MdDone, MdEdit } from 'react-icons/md'
import { useMutation } from 'react-query'
import ButtonLoader from 'components/buttonLoader'
import { updateStockQty } from '../queries/quotes'

interface IStockQtySelector {
    configurationId: number
    quantity: number
    refetch: () => void
}

const StockQuantitySelector = (props: IStockQtySelector) => {
    const { t } = useTranslation()
    const { configurationId, quantity: stockQty, refetch } = props
    const [qty, setQty] = useState<number>(stockQty)
    const [editMode, setEditMode] = useState<boolean>(false)

    const updateStockQtyMutation = useMutation(
        ['update-stock-mutation'],
        () =>
            updateStockQty(configurationId, {
                qty,
            }),
        {
            onSuccess: () => {
                refetch()
                toast.success(t('message.stock_qty_update_success'))
            },
            onError: (error: Error) => {
                toast.error(t(error?.message) || t('message.get_pricing_failed'))
                refetch()
            },
        },
    )

    if (updateStockQtyMutation.isLoading) {
        return <ButtonLoader />
    }

    if (!editMode) {
        return (
            <div className="flex gap-2 items-center">
                <p>{stockQty}</p>
                <MdEdit
                    className="mb-1 cursor-pointer"
                    size="16"
                    title={t('api.edit')}
                    onClick={() => setEditMode(true)}
                />
            </div>
        )
    }

    return (
        <Input
            name="stock_quantity"
            type="number"
            min={1}
            customInputStyles="p-0 text-right"
            inputWidth="w-[7rem]"
            value={qty}
            rightContent={
                <div className="flex gap-1">
                    <MdDone
                        className="cursor-pointer hover:text-green-300"
                        size={12}
                        title={t('api.ok')}
                        onClick={() => {
                            updateStockQtyMutation.mutate()
                            setEditMode(false)
                        }}
                    />
                    <MdCancel
                        className="cursor-pointer hover:text-dangerColor"
                        size={12}
                        title={t('api.cancel')}
                        onClick={() => {
                            setEditMode(false)
                            setQty(stockQty)
                        }}
                    />
                </div>
            }
            onChange={e => setQty(Number(e.target.value))}
        />
    )
}

export default StockQuantitySelector
