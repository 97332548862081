/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { Form, Input, Select } from 'antd'
import Fuse from 'fuse.js'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from 'components/button'
import Table from 'components/table'

import classnames from 'classnames'
import AlertModal from 'components/modal/alertModal'
import Tabs, { TabType } from 'components/tabs'
import { useFormik } from 'formik'
import { filter, isArray, map, uniq, uniqBy } from 'lodash'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { ChangeEvent, useEffect, useState } from 'react'
import { errorToast, successToast } from 'utils/toasterUtil'

import { useTranslation } from 'react-i18next'
import {
    getAllQuotes,
    getFleetQuote,
    getGenericQuestion,
    getTeamsQuotes,
    getWorkshopQuotes,
    orderQuoteData,
    updateExpectedDiscount,
    updateOrderOpportunity,
    updateQuoteStatus,
} from './queries/quotes'
import UpdateOwnershipAction from './components/UpdateOwnershipAction'

const cardClass = 'w-full bg-white px-4 py-4 rounded-md overscroll-auto overflow-auto'

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

type OrderOpportunityType = {
    label: number | string
    value: number | string
}

const Quotes = () => {
    const [query, setSearchQuery] = useState<string>('')
    const navigate = useNavigate()
    const { user, userExtra } = useAuth()

    const { t } = useTranslation()
    const [opportunity, setOpportunity] = useState<string>('all')
    const [status, setStatus] = useState<string>('pending')
    const [selectedUser, setSelectedUser] = useState<string>('all')
    const [quoteBasedOnUser, setQuoteBasedOnUser] = useState<string>(
        userExtra.role_id === ROLE_TYPE.fleet
            ? 'fleet'
            : userExtra.role_id === ROLE_TYPE.servicer
            ? 'workshop'
            : 'all',
    )

    const quoteType = [
        {
            label: t('fields.all_quote_type'),
            value: 'all',
        },
    ]

    if (userExtra.role_id === ROLE_TYPE.fleet) {
        quoteType.push({
            label: t('fields.fleet'),
            value: 'fleet',
        })
    }

    if (userExtra.role_id === ROLE_TYPE.servicer) {
        quoteType.push({
            label: t('fields.workshop'),
            value: 'workshop',
        })
    }

    const orderOpportunity: OrderOpportunityType[] = [
        {
            label: t('fields.all_order_opportunity'),
            value: 'all',
        },
        {
            label: 0,
            value: 0,
        },
        {
            label: 10,
            value: 10,
        },
        {
            label: 30,
            value: 30,
        },
        {
            label: 70,
            value: 70,
        },
        {
            label: 90,
            value: 90,
        },
    ]

    const statusDropDown: OrderOpportunityType[] = [
        {
            label: t('fields.all_status'),
            value: 'all',
        },
        {
            label: t('fields.pending'),
            value: 'pending',
        },
        {
            label: t('fields.lost'),
            value: 'lost',
        },
        {
            label: t('fields.postponed'),
            value: 'postponed',
        },
    ]

    const tabs: TabType[] =
        userExtra.role_id === ROLE_TYPE.account_manager
            ? [
                  {
                      name: 'api.my_quotes',
                      value: 'my-quotes',
                  },
                  {
                      name: 'api.team_quotes',
                      value: 'team-quotes',
                  },
              ]
            : [
                  {
                      name: 'api.my_quotes',
                      value: 'my-quotes',
                  },
                  {
                      name: 'api.team_quotes',
                      value: 'team-quotes',
                  },
              ]

    const [activeTab, setActiveTab] = useState(tabs[0])

    const [dataList, setDataList] = useState<any[]>([])
    const [statusSelectList, setStatusSelectList] = useState<OrderOpportunityType[]>([])
    const [userList, setUserList] = useState<OrderOpportunityType[]>([])

    const functionToCallQuote = (quoteFilter: string) => {
        if (quoteFilter === 'workshop') {
            return getWorkshopQuotes()
        }
        if (quoteFilter === 'fleet') {
            return getFleetQuote()
        }
        const isFleet = userExtra.role_id === ROLE_TYPE.fleet
        const isSupport = userExtra.role_id === ROLE_TYPE.technical_support
        return activeTab.value === 'team-quotes'
            ? getTeamsQuotes(user.id)
            : getAllQuotes(user.id, isFleet, isSupport)
    }

    const { data, isLoading, refetch } = useQuery(
        ['orderList', activeTab.value, quoteBasedOnUser],
        () => functionToCallQuote(quoteBasedOnUser),
        {
            onSuccess: d => {
                if (d.data && d.data.data && d.data.data.length > 0) {
                    const tempUserList: any[] = []
                    d.data.data.forEach((list: any) =>
                        tempUserList.push({
                            value: list.user_id,
                            label: list.user_name,
                        }),
                    )

                    const uniqueUserList = uniqBy(tempUserList, 'value')
                    setUserList([
                        {
                            label: t('fields.all_users'),
                            value: 'all',
                        },
                        ...uniqueUserList,
                    ])

                    const tempStatusList: string[] = []
                    d.data.data.forEach((list: any) => tempStatusList.push(list.status))
                    const uniqueStatus = uniq(tempStatusList)
                    setStatusSelectList([
                        {
                            label: t('fields.all_status'),
                            value: 'all',
                        },
                        ...uniqueStatus.map(s => ({
                            label: s,
                            value: s,
                        })),
                    ])
                }
            },
        },
    )

    const quotesData = data?.data ? (isArray(data?.data) ? data?.data : data?.data?.data) : []

    const fuse = new Fuse(quotesData, {
        shouldSort: true,
        threshold: 0.5,
        keys: ['quotation_name', 'quotation_for', 'brands'],
    })

    const result = fuse.search(query)
    const finalList = query
        ? result.map(item => {
              return item.item
          })
        : quotesData.filter(
              (item: { status: string }) =>
                  !(item.status === 'submitted order' || item.status === 'pending order'),
          )

    const onChangeOpportunity = (value: string) => {
        setOpportunity(value)
    }

    const onChangeStatus = (value: string) => {
        setStatus(value)
    }

    const onUserChange = (value: string) => {
        setSelectedUser(value)
    }

    const onChangeQuoteFilter = (value: string) => {
        setQuoteBasedOnUser(value)
        refetch()
    }

    const [alertModal, setAlertModal] = useState<any>({
        status: false,
        key: '',
        item: {},
        newValue: '',
    })

    const useMutationOptions = {
        onSuccess: (options: any) => {
            toast(options?.message || t('message.order_status_updated_successfully'), successToast)
            refetch()
            // navigate('/order')
        },
        onError: (error: any) => {
            toast(error?.message || t('message.failed_to_update_information'), errorToast)
        },
    }

    const updateOrder = useMutation(
        'updateOrderOpportunity',
        () =>
            updateOrderOpportunity(
                alertModal?.item?.id || '',
                alertModal?.newValue || alertModal?.item?.order_opportunity,
            ),
        useMutationOptions,
    )

    const updateExpDate = useMutation('updateExpDate', () =>
        updateExpectedDiscount(alertModal?.item?.id || '', alertModal?.newValue || ''),
    )

    const updateStatus = useMutation(
        'updateStatus',
        formData => updateQuoteStatus(alertModal?.item?.id, formData, user.id),
        useMutationOptions,
    )

    const createOrder = useMutation(
        'createOrder',
        () =>
            orderQuoteData(
                {
                    quote_id: alertModal?.item?.id,
                    quotation_name: alertModal?.item?.quotation_name,
                    configuration_id: alertModal?.item?.configuration_id,
                },
                user.id,
            ),
        useMutationOptions,
    )

    const genericeQuestion = useQuery('genericQuestion', () => getGenericQuestion())

    const closeAlertModal = () => {
        setAlertModal({
            status: false,
            key: '',
            item: {},
            newValue: '',
        })
    }

    const genericQuestionFormik = useFormik({
        initialValues: {
            generic_id: '',
            custom_comment: '',
            status: alertModal.newValue,
        },
        onSubmit: async (values: any) => {
            await updateStatus.mutate({
                ...values,
                status: alertModal.newValue,
            })
            closeAlertModal()
        },
    })

    const updateOrderQuantityForRow = async () => {
        await updateOrder.mutate()
        await refetch()
        closeAlertModal()
    }

    const orderQuoteInDetail = async () => {
        await createOrder.mutate()
        await refetch()
        closeAlertModal()
    }

    const updateExpectedDiscountForRow = async () => {
        await updateExpDate.mutate()
        await refetch()
        closeAlertModal()
    }

    const handleConfirm = () => {
        switch (alertModal.key) {
            case 'order_opportunity':
                return updateOrderQuantityForRow()
            case 'expected_delivery_date':
                return updateExpectedDiscountForRow()
            case 'status':
                return orderQuoteInDetail()
            default:
                return null
        }
    }

    const columns = [
        {
            name: t('api.s_no'),
            left: true,
            cell: (row: any, index: number) => (
                <h1 className="text-black text-semibold text-base">{index + 1 || 'unknown'}</h1>
            ),
            sortable: true,
            width: '120px',
        },
        {
            name: t('api.quote_name'),
            left: true,
            wrap: true,
            width: '240px',
            selector: (row: { quotation_name: string; id: string | number }) => {
                return (
                    <Link className="flex gap-4 items-center" to={`/quotes/${row.id}`}>
                        <a
                            href={`quotes/${row.id}`}
                            className="text-semibold text-base break-words break-all py-2"
                        >
                            {row?.quotation_name || 'unknown'}
                        </a>
                    </Link>
                )
            },
            sortable: true,
        },
        {
            name: t('api.quote_for'),
            left: true,
            width: '180px',
            sortable: true,
            wrap: true,
            selector: (row: { quotation_for: string }) => row.quotation_for,
        },
        {
            name: t('api.novo_quote_number_or_object_number'),
            left: true,
            width: '350px',
            height: 'fit-content',
            wrap: true,
            selector: (row: { novo_quote_numbers: any[]; object_numbers: any[] }) => {
                return (
                    <div className="grid grid-cols-2 gap-2 py-2">
                        {map(
                            row.novo_quote_numbers,
                            novoQuote =>
                                row.novo_quote_numbers && (
                                    <p
                                        className="text-black text-semibold text-base"
                                        key={novoQuote}
                                    >
                                        {novoQuote}
                                    </p>
                                ),
                        )}
                        {map(
                            row.object_numbers,
                            novoQuote =>
                                row.object_numbers && (
                                    <p
                                        className="text-black text-semibold text-base"
                                        key={novoQuote}
                                    >
                                        {novoQuote}
                                    </p>
                                ),
                        )}
                    </div>
                )
            },
            sortable: true,
        },
        // {
        //     name: 'Created',
        //     left: true,
        //     hide: 'lg',
        //     selector: (row: { created_at: string }) => {
        //         return (
        //             <p className="text-black text-semibold text-sm">
        //                 {moment(row.created_at).format(dateFormat) || '-'}
        //             </p>
        //         )
        //     },
        //     wrap: true,
        //     sortable: true,
        // },
        {
            name: t('api.quantity'),
            center: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: any) => row.quantity,
        },
        {
            name: t('api.opportunity_name'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '180px',
            selector: (row: any) => {
                return row.opportunity_names?.join(', ')
            },
        },
        {
            name: t('api.created_by'),
            left: true,
            omit: Boolean(activeTab.value !== 'team-quotes'),
            sortable: true,
            width: '240px',
            selector: (row: { user_id: string; id: string }) => (
                <UpdateOwnershipAction
                    quoteId={Number(row.id)}
                    ownerId={Number(row.user_id)}
                    refetch={refetch}
                    userList={userList}
                />
            ),
        },
        // {
        //     name: t('api.order_opportunity'),
        //     left: true,
        //     selector: (row: { order_opportunity: string }) => row.order_opportunity,
        //     cell: (row: { order_opportunity: string; id: string }) => {
        //         return (
        //             <Select
        //                 defaultValue={row?.order_opportunity || '0 %'}
        //                 size="large"
        //                 className="rounded-md w-24"
        //                 onChange={value => {
        //                     setAlertModal({
        //                         status: true,
        //                         key: 'order_opportunity',
        //                         item: row,
        //                         newValue: value,
        //                     })
        //                 }}
        //             >
        //                 {filter(orderOpportunity, item => item.label !== 'all').map(
        //                     (item: OrderOpportunityType) => (
        //                         <Select.Option value={item.value}>{item.label} %</Select.Option>
        //                     ),
        //                 )}
        //             </Select>
        //         )
        //     },
        //     sortable: true,
        // },
        // {
        //     name: t('api.e_delivery_date'),
        //     left: true,
        //     selector: (row: { expected_delivery_date: string }) => row.expected_delivery_date,
        //     cell: (row: {
        //         expected_delivery_date: string
        //         id: string
        //         order_opportunity: string
        //     }) => {
        //         if (!row.expected_delivery_date) {
        //             return (
        //                 <DatePicker
        //                     size="large"
        //                     className="rounded-md w-52"
        //                     onChange={(date, dateString) => {
        //                         setAlertModal({
        //                             status: true,
        //                             key: 'expected_delivery_date',
        //                             item: row,
        //                             newValue: dateString,
        //                         })
        //                     }}
        //                 />
        //             )
        //         }
        //         return (
        //             <DatePicker
        //                 onChange={(date, dateString) => {
        //                     setAlertModal({
        //                         status: true,
        //                         key: 'expected_delivery_date',
        //                         item: row,
        //                         newValue: dateString,
        //                     })
        //                 }}
        //                 size="large"
        //                 className="rounded-md w-52"
        //                 defaultValue={moment(row.expected_delivery_date, dateFormat)}
        //             />
        //         )
        //     },
        //     sortable: true,
        // },
        {
            name: t('api.status'),
            center: true,
            selector: (row: { status: string }) => row.status,
            cell: (row: { status: string; order_opportunity: string; id: string }) => {
                const baseType = String(row.status).split(' ')[0]
                return (
                    <Select
                        value={baseType}
                        size="large"
                        className="rounded-md w-52"
                        onChange={value => {
                            setAlertModal({
                                status: true,
                                key: 'status',
                                item: row,
                                newValue: value,
                            })
                        }}
                    >
                        {filter(statusDropDown, item => item.label !== 'all').map(
                            (item: OrderOpportunityType) => (
                                <Select.Option value={item.value}>{item.label}</Select.Option>
                            ),
                        )}
                    </Select>
                )
            },
            sortable: true,
        },
    ]

    const handleFilter = () => {
        if (finalList) {
            let tempDataList = finalList
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (opportunity !== 'all' && opportunity !== 0) {
                tempDataList = tempDataList.filter(
                    (item: { order_opportunity: string }) => item.order_opportunity === opportunity,
                )
            }
            if (status !== 'all') {
                tempDataList = tempDataList.filter(
                    (item: { status: string }) => item.status === status,
                )
            }
            if (selectedUser !== 'all') {
                tempDataList = tempDataList.filter(
                    (item: { user_id: number }) => Number(item.user_id) === Number(selectedUser),
                )
            }
            setDataList(tempDataList)
        }
    }

    useEffect(() => {
        setDataList(finalList)
    }, [query, data])

    useEffect(() => {
        handleFilter()
    }, [query, data, opportunity, status, selectedUser])

    return (
        <div className={classnames(cardClass, 'flex flex-col  bg-white h-full overflow-scroll')}>
            <Tabs
                initialTab={activeTab}
                tabs={tabs || []}
                getActiveTab={(tab: TabType) => {
                    setActiveTab(tab)
                    refetch()
                }}
                leftContent={
                    <div className="flex gap-4 justify-end items-center flex-wrap">
                        {Number(userExtra.role_id) === (ROLE_TYPE.fleet || ROLE_TYPE.servicer) ? (
                            <div>
                                <Select
                                    value={quoteBasedOnUser}
                                    size="large"
                                    className="w-48 rounded-md"
                                    placeholder={t('api.quote_filter')}
                                    onChange={onChangeQuoteFilter}
                                >
                                    {quoteType.map((item: OrderOpportunityType) => (
                                        <Select.Option value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        ) : null}
                        {/* <div>
                            <Select
                                value={opportunity}
                                size="large"
                                className="w-48 rounded-md"
                                placeholder={t('api.opportunity')}
                                onChange={onChangeOpportunity}
                            >
                                {orderOpportunity.map((item: OrderOpportunityType) => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                        </div> */}
                        <div>
                            <Select
                                value={status}
                                size="large"
                                className="w-48 rounded-md"
                                placeholder={t('api.status')}
                                onChange={onChangeStatus}
                            >
                                {statusSelectList.map((item: OrderOpportunityType) => (
                                    <Select.Option value={item.value}>{item.label}</Select.Option>
                                ))}
                            </Select>
                        </div>

                        {activeTab &&
                            (activeTab.value === 'team-quotes' ||
                                userExtra.role_id === ROLE_TYPE.fleet) && (
                                <div>
                                    <Select
                                        value={selectedUser}
                                        size="large"
                                        className="w-48 rounded-md uppercase"
                                        dropdownStyle={{ textTransform: 'uppercase' }}
                                        placeholder={t('api.user')}
                                        onChange={onUserChange}
                                    >
                                        {userList.map((item: OrderOpportunityType) => (
                                            <Select.Option value={item.value}>
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            )}

                        <div>
                            <Input
                                placeholder={t('api.search_quote')}
                                size="large"
                                className="rounded-md w-48 md:w-40"
                                defaultValue={query}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setSearchQuery(e.target.value)
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                id="create-quote"
                                label={t('api.create_quote')}
                                variant="outline"
                                onClick={() => navigate('/quotes/create')}
                            />
                        </div>
                    </div>
                }
            />
            <div className="border rounded-md border-gray overflow-auto">
                <Table
                    columns={columns}
                    data={dataList || []}
                    loading={isLoading}
                    striped
                    pagination
                    totalRows={25}
                    tableHeight="90%"
                />
            </div>
            <AlertModal
                show={
                    alertModal.status &&
                    alertModal.key === 'status' &&
                    alertModal.newValue !== 'ordered'
                }
                content={
                    <>
                        <p className="text-center text-lg">{t('api.status_change_alert')}</p>

                        <div className="bg-red-200 p-3 rounded-md mt-4">
                            <p className="mt-2 text-left text-sm">
                                {t('api.status_to_be_changed_from')} {alertModal?.item?.status}{' '}
                                {t('api.to')} {alertModal?.newValue}
                            </p>
                            <p className="text-left text-sm">
                                {t('api.do_you_want_to_change_the_status_of_this_quote')}{' '}
                                {alertModal?.item.id}
                            </p>
                        </div>
                    </>
                }
                isSubmitting={createOrder.isLoading}
                onConfirm={() => genericQuestionFormik.handleSubmit()}
                onCancel={() => closeAlertModal()}
            />
            <AlertModal
                show={
                    (alertModal.status && alertModal.key !== 'status') ||
                    (alertModal.status && alertModal.newValue === 'ordered')
                }
                content={
                    <div className="text-xl">
                        {t('api.are_you_sure_you_want_to_change_key')} {t(`api.${alertModal.key}`)}{' '}
                        {t('api.to')} {alertModal.newValue}
                    </div>
                }
                isSubmitting={updateOrder.isLoading || updateExpDate.isLoading}
                onConfirm={() => handleConfirm()}
                onCancel={() => closeAlertModal()}
            />
        </div>
    )
}

export default Quotes
