import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiEdit3, FiTrash } from 'react-icons/fi'
import { TbPlugConnected } from 'react-icons/tb'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import AlertModal from 'components/modal/alertModal'
import DeleteModal from 'components/modal/deleteModal'
import {
    addExtraItem,
    deleteExtraItemById,
    getConfigurationListByQuoteId,
    updateExtraItemById,
} from 'modules/quotes/queries/quotes'
import { useParams } from 'react-router-dom'
import EditExtraItemData from '../request_ts/EditExtraItemData'
import ConnectExtraToConfigurationsModal from './ConnectExtraToConfigurationsModal'

interface UpdateExtraItemProps {
    row: any
    columnKey: string
    refetch: () => void
}

const STATUS: any = {
    1: 'api.requested',
    2: 'api.done',
    3: 'api.rejected',
    4: 'api.servicer',
}

const UpdateExtraItem = (props: UpdateExtraItemProps) => {
    const { row, columnKey, refetch } = props
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const [editExtraItem, setEditExtraItem] = useState(false)
    const [deleteExtraItem, setDeleteExtraItem] = useState(false)
    const [showConnectExtraModel, setShowConnectExtraModel] = useState<boolean>(false)
    const [configurationList, setConfigurationList] = useState<any[]>([])
    const [qty, setQty] = useState<number>(row?.quantity)
    const [orderType, setOrderType] = useState<number>(row?.order_type)

    const deleteExtraItemMutation = useMutation(() => deleteExtraItemById(row.id, columnKey), {
        onSuccess: () => {
            toast(t('message.delete_item_success'))
            setDeleteExtraItem(false)
            refetch()
        },
    })

    const queryClient = useQueryClient()

    const configurationListMutation = useMutation(
        ['getConfigurationsListByQuoteId'],
        () => {
            let id = 0
            switch (columnKey) {
                case 'charger':
                    id = row.charger_id
                    break
                case 'battery':
                    id = row.battery_id
                    break
                case 'battery_accessories':
                    id = row.battery_acessories_id
                    break
                case 'hyster_battery':
                    id = row.hyster_battery_id
                    break
                case 'prep_list':
                    id = row.preplist_id
                    break
                default:
                    id = row.id
            }
            return getConfigurationListByQuoteId({
                column: columnKey,
                model: row.model,
                series: row.serie_code,
                quote_id: Number(quoteId),
                item_id: id,
            })
        },
        {
            onSuccess: (data: any) => {
                setConfigurationList(
                    data?.data?.data.map((configuration: any) => ({
                        label: `${configuration.novo_quote_number} ${t('api.model')}:${
                            configuration.model
                        } ${t('api.series')}:${configuration.series}`,
                        id: configuration.id,
                        checked: false,
                    })),
                )
            },
            onError: () => {
                toast.error(t('message.fetch_configuration_error'))
            },
        },
    )

    const addExtraItemForAttachmentMutation = useMutation(
        'addExtraItem',
        (configId: string) => {
            let id = 0
            switch (columnKey) {
                case 'charger':
                    id = row.charger_id
                    break
                case 'battery':
                    id = row.battery_id
                    break
                case 'battery_accessories':
                    id = row.battery_acessories_id
                    break
                case 'hyster_battery':
                    id = row.hyster_battery_id
                    break
                case 'prep_list':
                    id = row.preplist_id
                    break
                default:
                    id = row.id
            }
            return addExtraItem(configId || '', {
                column: columnKey,
                article: row.article,
                description: row.description,
                group: row.group,
                group_extra: row.group_extra,
                id,
                netTotal: row.netTotal,
                order_type: Number(row.order_type),
                price: row.price,
                quantity: row.quantity,
            })
        },
        {
            onSuccess: () => {
                toast.success(t('message.add_item_success'))
                queryClient.invalidateQueries(['extraItemData'])
            },
        },
    )

    const UpdateQuantityExtraItem = useMutation(
        ['updateQuantityExtraItem', row.id],
        (payload: any) => updateExtraItemById(row.id, columnKey, payload),
        {
            onSuccess: () => {
                toast(t('message.update_item_success'))
                setEditExtraItem(false)
                refetch()
            },
        },
    )

    const handleDeleteExtraItem = () => {
        deleteExtraItemMutation.mutate()
    }

    const handleUpdateQuantity = () => {
        const updatePayload = {
            quantity: qty,
            price: row.price,
            order_type: orderType,
        }
        UpdateQuantityExtraItem.mutate(updatePayload)
    }

    const handleAddExtra = () => {
        configurationList.forEach(c => {
            if (c.checked) {
                addExtraItemForAttachmentMutation.mutate(c.id)
            }
        })
        setShowConnectExtraModel(false)
    }

    return (
        <div className="flex items-center justify-center w-full gap-4">
            <button
                type="button"
                title={t('api.connect_to_other_configurations')}
                className="cursor-pointer hover:text-blue-700"
                onClick={() => {
                    configurationListMutation.mutate()
                    setShowConnectExtraModel(true)
                }}
            >
                <TbPlugConnected size={20} />
            </button>
            {STATUS?.[row?.status] !== 'api.rejected' ? (
                <span
                    className="cursor-pointer hover:text-blue-700"
                    onClick={() => setEditExtraItem(true)}
                >
                    <FiEdit3 size={20} />
                </span>
            ) : null}
            <span
                className="cursor-pointer hover:text-blue-700"
                onClick={() => setDeleteExtraItem(true)}
            >
                <FiTrash size={20} />
            </span>
            <AlertModal
                show={editExtraItem}
                content={
                    <EditExtraItemData
                        qty={qty}
                        setQty={setQty}
                        orderType={orderType}
                        setOrderType={setOrderType}
                        totalPrice={row.price * qty}
                    />
                }
                isSubmitting={UpdateQuantityExtraItem.isLoading}
                onConfirm={() => handleUpdateQuantity()}
                onCancel={() => setEditExtraItem(false)}
            />
            <AlertModal
                show={showConnectExtraModel}
                content={
                    <ConnectExtraToConfigurationsModal
                        id={row.id}
                        model={row.model}
                        description={row.description}
                        netTotal={
                            Number(row.total_price) || Number(row.price) * Number(row.quantity)
                        }
                        order_type={row.order_type}
                        price={row.price}
                        quantity={row.quantity}
                        configurationList={configurationList}
                        setConfigurationList={setConfigurationList}
                    />
                }
                isSubmitting={addExtraItemForAttachmentMutation.isLoading}
                onConfirm={() => handleAddExtra()}
                onCancel={() => setShowConnectExtraModel(false)}
            />
            <DeleteModal
                title={t('api.delete_extra_item_title')}
                label={t('api.delete_extra_item_body')}
                loading={deleteExtraItemMutation.isLoading}
                modal={deleteExtraItem}
                onConfirm={() => handleDeleteExtraItem()}
                closeModal={() => setDeleteExtraItem(false)}
            />
        </div>
    )
}

export default UpdateExtraItem
